
                  import { createNotivue } from 'notivue'
                  import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

                  function nullToInf(obj) {
                     if (obj == null) return 1 / 0

                     if (typeof obj === 'object') {
                        for (let key in obj) obj[key] = nullToInf(obj[key])
                     }

                     return obj
                  }                  
   
                  export default defineNuxtPlugin(({ vueApp }) => {
                     const options = useRuntimeConfig().public?.notivue || {}
                     const deserializedOpts = nullToInf(JSON.parse(JSON.stringify(options)))
                     delete deserializedOpts.addPlugin

                     const notivue = createNotivue(deserializedOpts)

                     vueApp.use(notivue)
                  })           
                  