<script setup lang="ts">
  import type { NuxtError } from '#app';

  const props = defineProps({
    error: Object as () => NuxtError,
  });

  const handleError = () => {
    clearError({ redirect: '/login' });
  };
</script>

<template>
  <NuxtLayout name="default">
    <div
      class="rounded-md flex flex-col min-w-full items-center min-h-full justify-center"
    >
      <div
        v-if="error"
        class="text-center"
      >
        <p class="font-bold text-[96px]">{{ error.statusCode }}</p>
        <p class="mt-12 mb-8 text-3xl">
          Whoops, it looks like we have unresolved issues &#128556; &#128530;
        </p>
        <p class="leading-loose text-xl">
          The app is reporting the following error:
        </p>
        <p class="leading-loose mb-12 text-xl">"{{ error.message }}"</p>

        <v-btn
          variant="flat"
          color="primary"
          height="52"
          @click="handleError"
        >
          <div class="normal-case text-xl px-8">Return to login</div>
        </v-btn>
      </div>
    </div>
  </NuxtLayout>
</template>
