import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/vercel/path1/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import vuetify_client_hints_client_umMQneJmAK from "/vercel/path1/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/vercel/path1/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path1/.nuxt/001.notivue.client.mjs";
import plugin_BqLkNUef68 from "/vercel/path1/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import strapi_CICqBBANNd from "/vercel/path1/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_nuxt3_gGdllsjxuZ from "/vercel/path1/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import plugin_AUP22rrBAc from "/vercel/path1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import fontawesome_klhsrycjcK from "/vercel/path1/plugins/fontawesome.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path1/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  vuetify_client_hints_client_umMQneJmAK,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  _001_notivue_client_bE2FHKqHiu,
  plugin_BqLkNUef68,
  strapi_CICqBBANNd,
  plugin_nuxt3_gGdllsjxuZ,
  plugin_AUP22rrBAc,
  fontawesome_klhsrycjcK,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]