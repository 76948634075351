import { default as _91wizard_937LLklT4RiNMeta } from "/vercel/path1/pages/[appMode]/[featureSlug]/[subfeatureSlug]/[supplierSlug]/[wizard].vue?macro=true";
import { default as indexmFaUW0TIBuMeta } from "/vercel/path1/pages/[appMode]/[featureSlug]/[subfeatureSlug]/index.vue?macro=true";
import { default as indexjgBJWGt9NAMeta } from "/vercel/path1/pages/[appMode]/[featureSlug]/index.vue?macro=true";
import { default as process_45completeNJVzTOMXklMeta } from "/vercel/path1/pages/[appMode]/status/process-complete.vue?macro=true";
import { default as _500CrI462m28ZMeta } from "/vercel/path1/pages/500.vue?macro=true";
import { default as actioningpU9wywyIhHMeta } from "/vercel/path1/pages/actioning.vue?macro=true";
import { default as indexZnoCdfqaF5Meta } from "/vercel/path1/pages/admin/[featureSlug]/[subfeatureSlug]/[[id]]/[[tab]]/index.vue?macro=true";
import { default as indexJ7ewXjtL7MMeta } from "/vercel/path1/pages/admin/[featureSlug]/index.vue?macro=true";
import { default as indexETedMd9U1pMeta } from "/vercel/path1/pages/app/[featureSlug]/index.vue?macro=true";
import { default as appK0FhpZfetoMeta } from "/vercel/path1/pages/app.vue?macro=true";
import { default as grant_45accessh3FqAtHog4Meta } from "/vercel/path1/pages/clickup/grant-access.vue?macro=true";
import { default as create_45passwordpblbhIbapnMeta } from "/vercel/path1/pages/create-password.vue?macro=true";
import { default as forgot_45passwordlK87AaDbMMMeta } from "/vercel/path1/pages/forgot-password.vue?macro=true";
import { default as _91articleSlug_93JWunRGBI5YMeta } from "/vercel/path1/pages/help/docs/[categorySlug]/[subcategorySlug]/[articleSlug].vue?macro=true";
import { default as docs11GiSLhe4IMeta } from "/vercel/path1/pages/help/docs.vue?macro=true";
import { default as indextjn3ueeLkPMeta } from "/vercel/path1/pages/help/faq/index.vue?macro=true";
import { default as SearchJniQwPWDHRMeta } from "/vercel/path1/pages/help/Search.vue?macro=true";
import { default as supportlKrbaoIRyQMeta } from "/vercel/path1/pages/help/support.vue?macro=true";
import { default as indexR8coiFTBs9Meta } from "/vercel/path1/pages/help/videos/index.vue?macro=true";
import { default as _91videoSlug_932pQYnje5zpMeta } from "/vercel/path1/pages/help/videos/watch/[videoSlug].vue?macro=true";
import { default as helpGBYL232UcvMeta } from "/vercel/path1/pages/help.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as loginlsVM2qGgsfMeta } from "/vercel/path1/pages/login.vue?macro=true";
import { default as reset_45password_45confirmationQkTt4I3CXWMeta } from "/vercel/path1/pages/reset-password-confirmation.vue?macro=true";
import { default as reset_45passwordRY4Jz2aDdWMeta } from "/vercel/path1/pages/reset-password.vue?macro=true";
import { default as grant_45accessxhXRcvTjMlMeta } from "/vercel/path1/pages/xero/grant-access.vue?macro=true";
export default [
  {
    name: "appMode-featureSlug-subfeatureSlug-supplierSlug-wizard",
    path: "/:appMode()/:featureSlug()/:subfeatureSlug()/:supplierSlug()/:wizard()",
    meta: _91wizard_937LLklT4RiNMeta || {},
    component: () => import("/vercel/path1/pages/[appMode]/[featureSlug]/[subfeatureSlug]/[supplierSlug]/[wizard].vue")
  },
  {
    name: "appMode-featureSlug-subfeatureSlug",
    path: "/:appMode()/:featureSlug()/:subfeatureSlug()",
    meta: indexmFaUW0TIBuMeta || {},
    component: () => import("/vercel/path1/pages/[appMode]/[featureSlug]/[subfeatureSlug]/index.vue")
  },
  {
    name: "appMode-featureSlug",
    path: "/:appMode()/:featureSlug()",
    meta: indexjgBJWGt9NAMeta || {},
    component: () => import("/vercel/path1/pages/[appMode]/[featureSlug]/index.vue")
  },
  {
    name: "appMode-status-process-complete",
    path: "/:appMode()/status/process-complete",
    meta: process_45completeNJVzTOMXklMeta || {},
    component: () => import("/vercel/path1/pages/[appMode]/status/process-complete.vue")
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/vercel/path1/pages/500.vue")
  },
  {
    name: "actioning",
    path: "/actioning",
    meta: actioningpU9wywyIhHMeta || {},
    component: () => import("/vercel/path1/pages/actioning.vue")
  },
  {
    name: "admin-featureSlug-subfeatureSlug-id-tab",
    path: "/admin/:featureSlug()/:subfeatureSlug()/:id?/:tab?",
    meta: indexZnoCdfqaF5Meta || {},
    component: () => import("/vercel/path1/pages/admin/[featureSlug]/[subfeatureSlug]/[[id]]/[[tab]]/index.vue")
  },
  {
    name: "admin-featureSlug",
    path: "/admin/:featureSlug()",
    meta: indexJ7ewXjtL7MMeta || {},
    component: () => import("/vercel/path1/pages/admin/[featureSlug]/index.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: appK0FhpZfetoMeta || {},
    component: () => import("/vercel/path1/pages/app.vue"),
    children: [
  {
    name: "app-featureSlug",
    path: ":featureSlug()",
    meta: indexETedMd9U1pMeta || {},
    component: () => import("/vercel/path1/pages/app/[featureSlug]/index.vue")
  }
]
  },
  {
    name: "clickup-grant-access",
    path: "/clickup/grant-access",
    meta: grant_45accessh3FqAtHog4Meta || {},
    component: () => import("/vercel/path1/pages/clickup/grant-access.vue")
  },
  {
    name: "create-password",
    path: "/create-password",
    meta: create_45passwordpblbhIbapnMeta || {},
    component: () => import("/vercel/path1/pages/create-password.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/vercel/path1/pages/forgot-password.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: helpGBYL232UcvMeta || {},
    component: () => import("/vercel/path1/pages/help.vue"),
    children: [
  {
    name: "help-docs",
    path: "docs",
    component: () => import("/vercel/path1/pages/help/docs.vue"),
    children: [
  {
    name: "help-docs-categorySlug-subcategorySlug-articleSlug",
    path: ":categorySlug()/:subcategorySlug()/:articleSlug()",
    component: () => import("/vercel/path1/pages/help/docs/[categorySlug]/[subcategorySlug]/[articleSlug].vue")
  }
]
  },
  {
    name: "help-faq",
    path: "faq",
    component: () => import("/vercel/path1/pages/help/faq/index.vue")
  },
  {
    name: "help-Search",
    path: "Search",
    component: () => import("/vercel/path1/pages/help/Search.vue")
  },
  {
    name: "help-support",
    path: "support",
    meta: supportlKrbaoIRyQMeta || {},
    component: () => import("/vercel/path1/pages/help/support.vue")
  },
  {
    name: "help-videos",
    path: "videos",
    component: () => import("/vercel/path1/pages/help/videos/index.vue")
  },
  {
    name: "help-videos-watch-videoSlug",
    path: "videos/watch/:videoSlug()",
    meta: _91videoSlug_932pQYnje5zpMeta || {},
    component: () => import("/vercel/path1/pages/help/videos/watch/[videoSlug].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginlsVM2qGgsfMeta || {},
    component: () => import("/vercel/path1/pages/login.vue")
  },
  {
    name: "reset-password-confirmation",
    path: "/reset-password-confirmation",
    meta: reset_45password_45confirmationQkTt4I3CXWMeta || {},
    component: () => import("/vercel/path1/pages/reset-password-confirmation.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordRY4Jz2aDdWMeta || {},
    component: () => import("/vercel/path1/pages/reset-password.vue")
  },
  {
    name: "xero-grant-access",
    path: "/xero/grant-access",
    meta: grant_45accessxhXRcvTjMlMeta || {},
    component: () => import("/vercel/path1/pages/xero/grant-access.vue")
  }
]