import validate from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/vercel/path1/middleware/history.global.ts";
import redirects_45global from "/vercel/path1/middleware/redirects.global.ts";
import manifest_45route_45rule from "/vercel/path1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  history_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/vercel/path1/middleware/admin.ts"),
  auth: () => import("/vercel/path1/middleware/auth.ts")
}